.view.account {
  padding: 30px 0;

  @media(min-width: $tablet-mid) {
    padding: 70px 0;
  }

  .account-card {
    margin-bottom: 20px;
  }

  &.filters {
    .select-filters {
      margin-bottom: 20px;
    }

    .btn {
      margin-top: 20px;
    }
  }

  &.team {
    .new-user-form {
      margin-top: 20px;

      .buttons {
        display: flex;
        margin-top: 20px;
      }
    }
  }

  &.join {
    h4 {
      margin-bottom: 30px;
    }

    .sub-title {
      color: $black;
    }

    .input-error {
      margin-top: -10px;
      margin-bottom: 5px;

      &.terms {
        margin: 0 0 0 -1.25rem;
      }
    }
  }
}
