.view.recruiter {
  padding-bottom: 30px;

  @media(min-width: $tablet-mid) {
    padding-bottom: 70px;
  }

  &.reviews {
    h1 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;

      @media(min-width: $tablet-mid) {
        margin-bottom: 80px;
      }
    }

    .reviews-section-card {
      margin-bottom: 20px;
    }
  }

  &.leave-review {
    h1 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;

      @media(min-width: $tablet-mid) {
        margin-bottom: 80px;
      }
    }

    .sub-title {
      color: $black;
    }

    input[type=radio] {
      margin-right: 5px;
    }

    div[role=group] {
      label {
        margin-right: 10px;
      }

      * {
        cursor: pointer;
      }
    }

    .rating-container {
      margin-bottom: 20px;
    }

    .input-error {
      margin-top: -10px;
    }
  }
}
