//CUSTOM VARIABLES
$rr-black: #313131;
$rr-blue: #1C7FB7;
$rr-green: #3D6632;
$rr-red: #B71C1C;

//BRAND VARIABLES
$brand-primary: #000000;
$brand-secondary: #ffffff;
$brand-tertiary: 0;

//COLORS
$black: #000000;
$white: #ffffff;
$light-grey: #C4C4C4;
$lighter-grey: #F4F4F4;
$grey: #797979;
$dark-grey: #3F3D56;
$error-red: #ff7e7f;

//GLOBAL VARIABLES
$headings-font-family: Helvetica;
$headings-font-weight: bold;
$headings-color: $black;

$paragraph-font-family: Helvetica;
$paragraph-font-weight: 400;
$paragraph-font-size: 14px;
$paragraph-line-height: 17px;
$paragraph-color: $black;
$paragraph-margin: 0;

$header-height: 0;
$logo-height: 0;

$button-min-width: 0;
$button-height: 47px;
$button-font-size: 14px;
$button-font-family: 'Raleway', sans-serif;
$button-font-weight: bold;
$button-line-height: $button-height;
$button-border-radius: 4px;
$button-padding: 0 30px;
$button-margin: 0;

$input-height: 40px;
$input-font-size: 16px;
$input-font-family: Helvetica;
$input-font-weight: 400;
$input-line-height: $input-height;
$input-color: $black;
$input-background-color: $white;
$input-border-radius: 4px;
$input-border-color: $grey;
$input-border-width: 1px;
$input-padding: 0 10px;
$input-margin: 0 0 20px;
$input-transition: box-shadow 0.55s ease-in-out;
$input-error-bs: 0px 0px 7px 1px rgba($error-red, 0.75);

$label-font-size: 14px;
$label-font-family: Helvetica;
$label-font-weight: bold;
$label-line-height: 17px;
$label-color: $black;
$label-padding: 0;
$label-margin: 0 0 4px;

$link-color: 0;
$link-font-weight: 0;

$card-padding: 30px;
$card-border-radius: 12px;
$card-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
$card-background: $white;

//RESPONSIVE
$mobile-large: 576px;
$tablet-portrait: 768px;
$tablet-mid: 992px;
$tablet-landscape: 1024px;
$desktop: 1200px;
$desktop-lg: 1400px;
$desktop-xl: 1780px;
