html, body {
  overflow-x: hidden;
  background: $lighter-grey;
}

* {
  font-family: 'Raleway', sans-serif;
}

p {
  margin: 0;
}

a {
  color: $rr-blue;
}

.wrapper-link {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.inputFile {
  display: none;
}
