.view.auth {
  padding-top: 100px;
  padding-bottom: 70px;

  h1 {
    font-size: 22px;
    font-weight: bold;
  }

  .auth-link {
    font-weight: 600;
    font-size: 14px;
    margin: 10px 0 20px;
  }

  .sub-title {
    color: $black;
  }

  label.sub-title {
    text-transform: uppercase;
  }

  .input-error {
    margin: -10px 0 5px;

    &.terms {
      margin: 0 0 0 -1.25rem;
    }
  }

  &.register {
    .row {
      margin-bottom: -10px;
    }
  }

  &.account-type {
    h1 {
      margin-bottom: 60px;
    }

    .btn {
      margin-bottom: 20px;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  &.add-details {
    h1 {
      margin-bottom: 40px;
    }

    .select-filters {
      margin-bottom: 35px;

      .input-error {
        margin-top: 10px;
        margin-bottom: -22px;
        display: block;
      }
    }

    .btn {
      margin-top: 30px;
      margin-left: auto;
      display: block;
    }
  }
}
