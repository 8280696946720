.app {
  .form-control {
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    background: $input-background-color;

    &:focus {
      box-shadow: none;
      background: $input-background-color;
      border: $input-border-width solid $input-border-color;
    }
  }

  textarea {
    resize: none;
  }

  .input-error {
    color: $error-red;
    font-size: 15px;
  }
}
