.btn {
  border-radius: $button-border-radius!important;
  border: none;
  height: $button-height;
  line-height: $button-line-height;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  padding: $button-padding!important;
  margin: $button-margin;
  min-width: $button-min-width;
  box-shadow: none!important;

  &:focus {
    outline: none;

  }

  &.btn-primary {
    background: $rr-blue;
  }

  &.btn-secondary {
    background: $rr-green;
  }

  &.btn-red {
    background: $rr-red;
    color: $white;
  }
}
