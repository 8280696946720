.cookie-message {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 15px 0;
  background: $white;
  z-index: 9999999999;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  @media (min-width: $tablet-portrait) {
    padding: 20px 0;
  }

  .row {
    > div {
      display: flex;
      justify-content: center;
      align-items: center;

      &.icon {
        display: none;

        @media (min-width: $tablet-portrait) {
          display: flex;
        }
      }
    }
  }

  img {
    max-width: 60px;
  }

  p {
    margin-bottom: 5px;

    @media (min-width: $tablet-portrait) {
      margin: 0;
    }

    a {
      text-decoration: underline;
    }
  }
}

.rr-card {
  background: $white;
  border-radius: 4px;
  padding: 35px 45px;
}

.rating-container {
  display: flex;

  &.clickable {
    img {
      cursor: pointer;
      margin-right: 5px;
    }
  }
}

.search-bar {
  padding: 20px 0;
  margin-bottom: 30px;

  @media(min-width: $tablet-mid) {
    margin-bottom: 70px;
    height: 150px;
    padding: 40px 0;
  }

  &.recruiters {
    background: $rr-blue;
  }

  .search-box {
    flex: 0.85;
    height: 50px;
    border: 1px solid rgba($black, 0.19)!important;
    border-radius: 36.5px 0px 0px 36.5px!important;
    margin: 0;
    padding-left: 10px;

    @media(min-width: $tablet-mid) {
      padding-left: 75px;
      flex: 0.75;
      height: 75px;
    }
  }

  .search-group {
    display: flex;
    align-items: center;

    .location-selection {
      flex: 0.15;
      height: 50px;
      background: $white;
      border: 1px solid rgba($black, 0.19);
      border-radius: 0 36.5px 36.5px 0;

      appearance: none;
      cursor: pointer;

      @media(min-width: $tablet-mid) {
        flex: 0.25;
        padding-left: 35px;
        height: 75px;
      }
    }

    .search-button {
      margin-left: 15px;
      cursor: pointer;
      height: 42px;

      @media(min-width: $tablet-mid) {
        height: initial;
        margin-left: 25px;
      }
    }

    .loader {
      margin-left: 15px;
      width: 42px;
      display: flex;
      align-items: center;

      @media(min-width: $tablet-mid) {
        width: 52px;
        margin-left: 25px;
      }

      i {
        font-size: 42px;
        color: $white;
      }
    }
  }
}

.filters-card {
  @extend .rr-card;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media(max-width: ($tablet-portrait - 1px)) {
    padding: 20px 25px;
  }

  &.open {
    @media(max-width: ($tablet-portrait - 1px)) {
      .title {
        margin: 0 0 10px;
      }

      .filters {
        display: initial;
      }
    }
  }

  .title {
    font-size: 14px;
    font-weight: 700;
    color: $black;
    margin: 0;
    text-transform: uppercase;
    display: flex;

    @media(min-width: $tablet-portrait) {
      margin: 0 0 10px;
    }

    .fas {
      display: inline-block;
      margin-left: auto;
    }
  }

  .filters {
    display: none;

    @media(min-width: $tablet-portrait) {
      display: initial;
    }
  }

  .filter {
    margin: 4px -15px;
    cursor: pointer;

    &.active {
      .square {
        background: $rr-blue;
      }
    }

    .fa-check-square, .fa-square {
      color: $rr-blue;
      font-size: 20px;
      margin-top: 2.5px;
    }

    .fa-square {
      color: $black;
    }

    .col-10 {
      align-items: center;
      display: flex
    }

    .square {
      height: 20px;
      width: 20px;
      border: 0.5px solid $black;
      border-radius: 4px;
    }

    .filter-name {
      font-size: 16px;
      line-height: 16px;
      user-select: none;
    }

  }
}

.recruiters-list {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 22px;
    font-weight: 600;
    color: $black;
    margin: 0 0 10px;
  }

  hr {
    border-color: $grey;
    margin: 0 0 15px;
  }
}

.recruiter-card {
  @extend .rr-card;
  margin: 15px 0;

  //temp
  .img {
    height: 200px;
  }

  .img, .rec-logo {
    display: block;
    background: $light-grey;
    width: 65%;
    min-height: 125px;
    margin: 0 0 15px;

    @media (min-width: $tablet-mid) {
      width: 125px;
      height: 125px;
      margin: 0 auto;
    }
  }


  .title {
    font-size: 22px;
    font-weight: 600;
    color: $black;
    margin: 0;
  }

  .bio {
    font-size: 14px;
    color: $black;
    margin: 0;
  }

  .categories {
    display: flex;
    margin-top: 10px;

    .category {
      background: $black;
      color: $white;
      font-size: 12px;
      font-weight: 700;
      padding: 4px 25px;
      margin-right: 15px;
      display: flex;
      align-items: center;
    }
  }
}

.sub-title {
  font-size: 12px;
  font-weight: 900;
  color: $light-grey;
  margin: 10px 0 5px;
  display: block;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.recruiter-rating-row {
  .rating-wrapper {
    display: flex;
    align-items: self-end;
    justify-content: left;

    .rating-count {
      margin-left: 10px;
      font-size: 18px;
      line-height: 20px;
      color: $rr-blue;
    }
  }
}

.pagination {
  margin: 15px 0 30px;
  color: $rr-blue;
  display: flex;
  justify-content: center;
  font-size: 18px;

  div {
    margin: 0 6px;
    cursor: pointer;

    &.active {
      font-weight: 700;
    }
  }
}

.recruiter-info-card {
  @extend .rr-card;
  margin: 0 0 30px;

  .rec-logo, .img {
    width: 100%;
    margin-bottom: 30px;

    @media (min-width: $tablet-mid) {
      margin: 0;
    }
  }

  .title {
    font-size: 22px;
    font-weight: 600;
    color: $black;
    margin: 0 0 10px;
  }

  .recruiter-rating-row {
    margin-bottom: 20px;
  }

  .btn {
    min-width: 220px;
    margin: 30px 20px 0 0;
  }

  .social-links {
    display: flex;
    font-size: 35px;

    a {
      margin-right: 10px;

      .fa-globe {
        color: $rr-red;
      }

      .fa-linkedin {
        color: #0077B7;
      }

      .fa-twitter-square {
        color: #50ABF1;
      }

      .fa-facebook-square {
        color: #4267B2;
      }
    }
  }
}


.recruiter-bio-card {
  @extend .rr-card;
  margin: 0 0 30px;

  .sub-title {
    color: $black;
    margin: 0 0 15px;
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }
}

.recruiter-contact-card {
  @extend .rr-card;
  margin: 0 0 30px;

  .sub-title {
    color: $black;
    margin: 0 0 15px;
    font-size: 14px;
  }

  .item {
    margin: 10px 0;
    display: block;
    font-size: 14px;
  }
}

.recruiter-contact-form-card {
  @extend .rr-card;
  margin: 0 0 30px;

  .sub-title {
    color: $black;
    margin: 0 0 15px;
    font-size: 14px;
  }

  .input-error {
    margin: -10px 0 10px;
  }
}

.recruiter-reviews-card {
  @extend .rr-card;
  margin: 0 0 30px;

  .sub-title {
    color: $black;
    margin: 0 0 15px;
    font-size: 14px;
  }

  .tab-row {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    @media(min-width: $tablet-mid) {
      margin-bottom: 40px;
      justify-content: initial;
    }

    .tab-link {
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;
      padding: 5px 0;
      border-bottom: 10px solid transparent;
      text-transform: uppercase;

      @media(min-width: $tablet-mid) {
        margin-right: 35px;
      }

      &.active {
        font-weight: 900;

        &.com {
          border-color: $rr-green;
        }

        &.cand {
          border-color: $rr-blue;
        }
      }
    }

    .see-more {
      margin-left: auto;
      color: $rr-blue;
      font-size: 14px;
      flex: 1 100%;
      margin-top: 15px;

      @media(min-width: $tablet-mid) {
        flex: initial;
        margin-top: 0;
      }

      .fa-chevron-right {
        margin-left: 15px;
        font-size: 13px;
      }
    }
  }

  .recruiter-review {
    &:last-of-type {
      margin: 0;
    }
  }

  .recruiter-rating-summary {
    margin-bottom: 30px;
  }
}

.rating-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  span {
    display: block;
    font-size: 14px;
    margin-left: 10px;
  }
}

.review-count {
  display: block;
  margin-bottom: 15px;

  @media(min-width: $tablet-mid) {
    margin-bottom: 30px;
  }
}

.rating-breakdown {
  margin-bottom: 20px;

  @media(min-width: $tablet-mid) {
    margin-bottom: 40px;
  }
}

.rating-item {
  margin-bottom: 10px;

  > div {
    display: flex;
    align-items: center;

    img {
      display: block;
      margin-left: 5px;
    }
  }
}

.rating-progress {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  border-radius: 4px;
  border: 1px solid $light-grey;
  background: $white;
  width: 100%;

  .progress-bar {
    border-radius: 0 2px 2px 0;
    transition: width .6s ease, background-color .6s;
  }

  &.company {
    .progress-bar {
      background: $rr-green;
    }
  }

  &.candidate {
    .progress-bar {
      background: $rr-blue;
    }
  }
}

.recruiter-review {
  border: 1px solid $light-grey;
  border-radius: 4px;
  padding: 35px 30px;
  margin-bottom: 20px;

  .review-title {
    display: block;
    margin: 10px 0;
    font-size: 22px;
    font-weight: 700;
  }
}

.reviews-section-card {
  @extend .rr-card;
  padding: 20px 35px;

  .sub-title {
    color: $black;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .recruiter-rating-summary {
    margin-bottom: 30px;
  }
}

.auth-card {
  @extend .rr-card;

  padding: 70px 85px;
  max-width: 850px;
  margin: 0 auto;
}

.account-card {
  @extend .rr-card;
}

.account-navigation {
  @extend .rr-card;
  margin-bottom: 25px;
  display: flex;

  @media(max-width: ($tablet-portrait - 1px)) {
    padding: 20px 25px;
    display: block;
  }

  a {
    margin-right: 40px;
    font-weight: 600;
    color: $rr-blue;

    &:hover {
      text-decoration: none;
      border-bottom: 5px solid $rr-blue;
    }

    &.active {
      font-weight: bold;
      border-bottom: 5px solid $rr-blue;
    }

    @media(max-width: ($tablet-portrait - 1px)) {
      margin: 2.5px 20% 2.5px 0;
      display: inline-block;
    }
  }
}

.logo-upload {
  max-width: 30%;

  &.active {
    max-width: 100%;
  }

  img {
    width: 100%;
  }
}

.team-table {
  margin: 20px 0;
}

.new-user-form {
  .buttons {
    display: flex;
    justify-content: flex-end;

    .btn {
      margin-left: 0!important;
    }
  }
}
