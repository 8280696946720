.main-header {
  color: $white;
  background: $rr-black;
  padding: 15px 20px;
  width: 100vw;
  z-index: 1000;
  position: relative;

  @media (min-width: $tablet-portrait) {
    padding: 25px 40px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 15px;

    .left {
      //width: 25%;
      @media (min-width: $tablet-portrait) {
        width: 25%;
      }
    }

    .header-actions {
      //width: 25%;
      @media (min-width: $tablet-portrait) {
        width: 150px;
      }
    }

    .desktop {
      display: none;
      @media (min-width: $tablet-portrait) {
        display: initial;
      }
    }
    .mobile {
      @media (min-width: $tablet-portrait) {
        display: none;
      }
    }

    .brand {
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;
      margin-right: 10px;

      .logo {
        height: 60px;
      }

      a {
        color: inherit;
      }
    }

    nav {
      width: calc(75% - 150px);
      text-align: right;
      background: $rr-black;
      position: absolute;
      height: 0;
      top: 100%;
      width: 100%;
      left: 0;
      text-align: left;
      overflow: hidden;

      @media (min-width: $tablet-portrait) {
        display: initial;
        height: unset;
        width: unset;
        position: initial;
        overflow: visible;
      }

      &.open {
        height: unset;
        padding: 0 35px 20px;
      }

      a {
        padding: 0 0 2.5px;
        margin: 2.5px 80% 2.5px 0;
        color: inherit;
        text-decoration: none;
        display: inline-block;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        &.active {
          font-weight: 600;
          border-bottom: 6px solid #3D6632;
        }

        @media (min-width: $tablet-portrait) {
          display: initial;
          margin: 0 15px;
          padding: 0 0 5px;
        }
      }
    }

    .header-actions {
      text-align: right;
    }
  }
}
